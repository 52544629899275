import type { ClientConfiguration } from '../models/ClientConfiguration';
import { get } from '../utils/http';
import { getPrimerHeaders } from './getPrimerHeaders';

export async function fetchClientConfiguration(
  accessToken: string,
  configurationUrl: string,
  checkoutSessionId: string,
) {
  try {
    return await get<ClientConfiguration>(
      `${configurationUrl}/?withDisplayMetadata=true`,
      getPrimerHeaders(accessToken, checkoutSessionId, '2.1'),
    );
  } catch (error) {
    throw new Error(
      [
        'Failed to initialize client',
        JSON.stringify(error || undefined, undefined, 2),
      ]
        .filter(Boolean)
        .join(': '),
    );
  }
}
