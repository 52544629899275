import CallQueue from '../../utils/CallQueue';
import { post } from '../../utils/http';
import {
  Analytics,
  AnalyticsEvent,
  AnalyticsEventProperties,
  AnalyticsEvents,
  AnalyticsOptions,
  createAnalyticsEvent,
} from '../types';

const defaultUrl = process.env.PRIMER_ANALYTICS_API_URL as string;

export function createHttpAnalyticsProvider({
  url = defaultUrl,
  ...options
}: AnalyticsOptions & { url?: string } = {}): Analytics {
  const callQueue = new CallQueue();
  callQueue.handleCall = (events: AnalyticsEvent<AnalyticsEventProperties>[]) =>
    post(url, events);
  callQueue.handleMerge = (previousData, data) => [...previousData, ...data];

  const trackEvent = (eventType: AnalyticsEvents) => async (
    properties: AnalyticsEventProperties,
  ) => {
    // Server Side Rendering check
    if (typeof navigator === `${undefined}`) return;

    const event = await createAnalyticsEvent<AnalyticsEventProperties>(
      eventType,
      properties,
      options,
    );

    await callQueue.call([event]);
  };

  return {
    crashEvent: trackEvent(AnalyticsEvents.CRASH_EVENT),
    messageEvent: trackEvent(AnalyticsEvents.MESSAGE_EVENT),
    networkCallEvent: trackEvent(AnalyticsEvents.NETWORK_CALL_EVENT),
    sdkFunctionEvent: trackEvent(AnalyticsEvents.SDK_FUNCTION_EVENT),
    timerEvent: trackEvent(AnalyticsEvents.TIMER_EVENT),
    v1Event: trackEvent(AnalyticsEvents.V1_EVENT),
  };
}
