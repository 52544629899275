import { createAnalytics } from '../analytics';
import { prepareNetworkEventProps } from '../analytics/utils';
import { decodeClientToken } from '../core/decodeClientToken';
import { fetchClientConfiguration } from '../core/fetchClientConfiguration';
import { uuid } from '../utils/uuid';
import { loadPrimer } from './loadPrimer';

type PrimerClient = typeof import('../Primer');
type Tail<T extends unknown[]> = T extends [any, any, ...infer P] ? P : never;
type PrimerFunctions = Exclude<keyof PrimerClient, 'SDK_VERSION'>;
type ArgsOf<T extends PrimerFunctions> = Tail<Parameters<PrimerClient[T]>>;
type ReturnTypeOf<T extends PrimerFunctions> = Awaited<
  ReturnType<PrimerClient[T]>
>;

export const SDK_VERSION = process.env.PRIMER_SDK_VERSION as string;

export const createHeadless = lazyLoad('createHeadless');
export const showExpressCheckout = lazyLoad('showExpressCheckout');
export const showUniversalCheckout = lazyLoad('showUniversalCheckout');
export const showVaultManager = lazyLoad('showVaultManager');

const messageType = 'LOAD_PERFORMANCE';

const checkoutSessionId = uuid();
let analytics = createAnalytics({ checkoutSessionId });

analytics.messageEvent({
  message: 'parsed',
  messageType,
  // eslint-disable-next-line compat/compat
  now: performance.now?.(),
});

function lazyLoad<FnName extends PrimerFunctions>(name: FnName) {
  return async (
    ...[clientToken, options]: ArgsOf<FnName>
  ): Promise<ReturnTypeOf<FnName>> => {
    analytics.sdkFunctionEvent({ name });
    analytics.messageEvent({
      message: 'create',
      messageType,
      // eslint-disable-next-line compat/compat
      now: performance.now?.(),
    });

    const [config, client] = await Promise.all([
      getConfiguration(clientToken),
      loadPrimer(),
    ]);

    return client[name](
      config,
      checkoutSessionId,
      clientToken,
      options,
    ) as ReturnTypeOf<FnName>;
  };
}

async function getConfiguration(clientToken: string) {
  const {
    accessToken,
    configurationUrl,
    analyticsUrlV2: analyticsUrl,
  } = decodeClientToken(clientToken);

  analytics = createAnalytics({ checkoutSessionId, url: analyticsUrl });

  const url = `${configurationUrl}/?withDisplayMetadata=true`;
  const id = uuid();
  const payload = { method: 'get' as any, url };
  analytics.networkCallEvent(prepareNetworkEventProps(id, payload));

  const config = await fetchClientConfiguration(
    accessToken,
    configurationUrl,
    checkoutSessionId,
  );

  const { primerAccountId } = config;
  const { clientSessionId } = config.clientSession;
  analytics = createAnalytics({
    checkoutSessionId,
    clientSessionId,
    primerAccountId,
    url: analyticsUrl,
  });

  analytics.networkCallEvent(
    prepareNetworkEventProps(id, payload, { data: config }),
  );

  return config;
}
