export const get = <T>(url: string, headers?: Record<string, string>) =>
  request<T>('get', url, headers);

export const post = <T>(
  url: string,
  payload: Record<string, any>,
  headers?: Record<string, string>,
) => request<T>('post', url, headers, JSON.stringify(payload));

const contentType = {
  'content-type': 'application/json',
};

// TODO: update with `fetch` when IE support is dropped
const request = <T>(
  method: 'get' | 'post',
  url: string,
  headers?: Record<string, string>,
  body?: string,
) =>
  new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const options = { ...contentType, ...headers };
    xhr.open(method, url, true);
    Object.entries(options).forEach(([key, value]) =>
      xhr.setRequestHeader(key, value),
    );
    xhr.onload = () => resolve(read());
    xhr.onerror = () => reject(read());
    xhr.send(body);

    // eslint-disable-next-line consistent-return
    function read() {
      try {
        return JSON.parse(xhr.responseText);
      } catch {}
    }
  });
